import { apiGetAuth, apiGetAuthWithCustomHeadersResponseType } from '@/utils/api';
import { API_MASTER } from '@/utils/api-url';
import { exportCustomHeaderAndResponseType } from '@/utils/helper'

const state = {
    report_brandings: [],
    report_branding: null,
    total: 0,
    loading: false,
    error: null
};

const mutations = {
    SET_REPORT_BRANDINGS(state, report_brandings) {
        state.report_brandings = report_brandings;
    },
    SET_REPORT_BRANDING(state, report_branding) {
        state.report_branding = report_branding;
    },
    SET_TOTAL(state, total) {
        state.total = total;
    },
    SET_LOADING(state, isLoading) {
        state.loading = isLoading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
};

const actions = {
    async getReportBrandings({ commit }, { limit, page, search, startDate, endDate, status }) {
        commit('SET_LOADING', true);
        try {
            const response = await apiGetAuth(`${API_MASTER.REPORT_BRANDING}?limit=${limit}&page=${page}&search=${search}&start_date=${startDate}&end_date=${endDate}&status=${status}`);
            if (response.data.status_code === 200) {

                commit('SET_REPORT_BRANDINGS', response.data.data.data);
                commit('SET_TOTAL', response.data.data.total);
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async exportReportBrandings({ commit }, { limit, page, search, startDate, endDate, status }) {
        commit('SET_LOADING', true);
        try {
            const response = await apiGetAuthWithCustomHeadersResponseType(`${API_MASTER.REPORT_BRANDING}/export?limit=${limit}&page=${page}&search=${search}&start_date=${startDate}&end_date=${endDate}&status=${status}`);
            exportCustomHeaderAndResponseType(response.data, `Export Report.xlsx`)
            if (response.data.status_code === 200) {

                commit('SET_REPORT_BRANDINGS', response.data.data.data);
                commit('SET_TOTAL', response.data.data.total);
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async getReportBrandingsWithStatus({ commit }, status) { 
        commit('SET_LOADING', true); 
        try { 
          const response = await apiGetAuth(`${API_MASTER.REPORT_BRANDING}?limit=100&page=1&status=${status}`); 
          if (response.data.status_code === 200) { 
            return response.data.data.data.map(ca => ({
              id: ca.ca_number,
              value: ca.ca_number,
              name: ca.ca_number
            }));
          } else { 
            throw new Error(response.data.message); 
          } 
        } catch (error) { 
          commit('SET_ERROR', error.message); 
        } finally { 
          commit('SET_LOADING', false); 
        } 
      },


    setSelectedReportBranding({ commit }, poEvent) {
        commit('SET_REPORT_BRANDING', poEvent);
    }

};

const getters = {
    getReportBrandings(state) {
        return state.report_brandings;
    },
    getTotalReportBrandings(state) {
        return state.total;
    },
    isLoading(state) {
        return state.loading;
    },
    getError(state) {
        return state.error;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
