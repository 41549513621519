import { apiGetAuth } from '@/utils/api';
import { API_MASTER, API_AUTH } from '@/utils/api-url';

const state = {
    heatmaps: [],
    heatmap: null,
    total: 0,
    loading: false,
    error: null
};

const mutations = {
    SET_HEATMAPS(state, heatmaps) {
        state.heatmaps = heatmaps;
    },
    SET_HEATMAP(state, heatmap) {
        state.heatmap = heatmap;
    },
    SET_TOTAL(state, total) {
        state.total = total;
    },
    SET_LOADING(state, isLoading) {
        state.loading = isLoading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
};

const actions = {
    async fetchHeatmap({ commit }, { region_id, branch_id, date_from, date_to, heatmap_type }) {
        commit('SET_LOADING', true);
        try {
            const response = await apiGetAuth(`${API_MASTER.HEATMAP}?region_id=${region_id}&branch_id=${branch_id}&date_from=${date_from}&date_to=${date_to}&heatmap_type=${heatmap_type}`);
            if (response.data.status_code === 200) {
                commit('SET_HEATMAPS', response.data.data);
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async getRegion({ commit }) {

        commit('SET_LOADING', true);
        try {
            const response = await apiGetAuth(`${API_AUTH.REGION}`);
            if (response.data.status_code === 200) {
                return response.data.data.map(region => ({
                    id: region.id,
                    value: region.id,
                    name: region.name
                }));
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async getSubRegionById({ commit }, id) {
        commit('SET_LOADING', true);
        try {
            const response = await apiGetAuth(`${API_AUTH.SUB_REGION}?id=${id}`);
            if (response.data.status_code === 200) {
                return response.data.data.map(sub_region => ({
                    id: sub_region.id,
                    value: sub_region.id,
                    name: sub_region.name
                }));
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
        } finally {
            commit('SET_LOADING', false);
        }
    },
    async getBranchesById({ commit }, id) {

        commit('SET_LOADING', true);
        try {
            const response = await apiGetAuth(`${API_AUTH.BRANCHES}?id=${id}`);
            if (response.data.status_code === 200) {
                return response.data.data.map(branches => ({
                    id: branches.id,
                    value: branches.id,
                    name: branches.name
                }));
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
        } finally {
            commit('SET_LOADING', false);
        }
    },
};

const getters = {
    getHeatmaps(state) {
        return state.heatmaps;
    },
    getTotalHeatmap(state) {
        return state.total;
    },
    isLoading(state) {
        return state.loading;
    },
    getError(state) {
        return state.error;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
