import { apiGetAuth, apiGetAuthWithCustomHeadersResponseType } from '@/utils/api';
import { API_MASTER } from '@/utils/api-url';
import { exportCustomHeaderAndResponseType } from '@/utils/helper'

const state = {
    report_compensations: [],
    report_compensation: null,
    total: 0,
    loading: false,
    error: null
};

const mutations = {
    SET_REPORT_COMPENSATIONS(state, report_compensations) {
        state.report_compensations = report_compensations;
    },
    SET_REPORT_COMPENSATION(state, report_compensation) {
        state.report_compensation = report_compensation;
    },
    SET_TOTAL(state, total) {
        state.total = total;
    },
    SET_LOADING(state, isLoading) {
        state.loading = isLoading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
};

const actions = {
    async getReportCompensations({ commit }, { limit, page, search, startDate, endDate, status }) {
        commit('SET_LOADING', true);
        try {
            const response = await apiGetAuth(`${API_MASTER.REPORT_COMPENSATION}?limit=${limit}&page=${page}&search=${search}&start_date=${startDate}&end_date=${endDate}&status=${status}`);
            if (response.data.status_code === 200) {

                commit('SET_REPORT_COMPENSATIONS', response.data.data.data);
                commit('SET_TOTAL', response.data.data.total);
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async exportReportCompensations({ commit }, { limit, page, search, startDate, endDate, status }) { 
        commit('SET_LOADING', true); 
        try { 
          const response = await apiGetAuthWithCustomHeadersResponseType(`${API_MASTER.REPORT_COMPENSATION}/export?limit=${limit}&page=${page}&search=${search}&start_date=${startDate}&end_date=${endDate}&status=${status}`); 
          exportCustomHeaderAndResponseType(response.data, `Export Report.xlsx`)
          if (response.data.status_code === 200) { 
            
            commit('SET_REPORT_COMPENSATIONS', response.data.data.data); 
            commit('SET_TOTAL', response.data.data.total); 
          } else { 
            throw new Error(response.data.message); 
          } 
        } catch (error) { 
          commit('SET_ERROR', error.message); 
        } finally { 
          commit('SET_LOADING', false); 
        } 
      },
    

    setSelectedReportCompensation({ commit }, poEvent) {
        commit('SET_REPORT_COMPENSATION', poEvent);
    }

};

const getters = {
    getReportCompensations(state) {
        return state.report_compensations;
    },
    getTotalReportOthers(state) {
        return state.total;
    },
    isLoading(state) {
        return state.loading;
    },
    getError(state) {
        return state.error;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
