import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth, apiGetAuthWithCustomHeadersResponseType } from '@/utils/api'; 
import { API_MASTER, API_AUTH } from '@/utils/api-url'; 
import { exportCustomHeaderAndResponseType } from '@/utils/helper'

const state = { 
  report_operationals: [], 
  report_other: null, 
  total: 0, 
  loading: false, 
  error: null 
}; 

const mutations = { 
  SET_REPORT_OPERATIONAL(state, report_operationals) { 
    state.report_operationals = report_operationals; 
  }, 
  SET_REPORT_OTHER(state, report_other) { 
    state.report_other = report_other; 
  }, 
  SET_TOTAL(state, total) { 
    state.total = total; 
  }, 
  SET_LOADING(state, isLoading) { 
    state.loading = isLoading; 
  }, 
  SET_ERROR(state, error) { 
    state.error = error; 
  } 
}; 

const actions = { 
  async getReportOperationals({ commit }, { limit, page, search, startDate, endDate, status }) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.REPORT_OPERATIONAL}?limit=${limit}&page=${page}&search=${search}&start_date=${startDate}&end_date=${endDate}&status=${status}`); 
      if (response.data.status_code === 200) { 
        
        commit('SET_REPORT_OPERATIONAL', response.data.data.data); 
        commit('SET_TOTAL', response.data.data.total); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async exportReportOperationals({ commit }, { limit, page, search, startDate, endDate, status }) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuthWithCustomHeadersResponseType(`${API_MASTER.REPORT_OPERATIONAL}/export?limit=${limit}&page=${page}&search=${search}&start_date=${startDate}&end_date=${endDate}&status=${status}`); 
      exportCustomHeaderAndResponseType(response.data, `Export Report.xlsx`)
      if (response.data.status_code === 200) { 
        
        commit('SET_REPORT_OPERATIONAL', response.data.data.data); 
        commit('SET_TOTAL', response.data.data.total); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getReportOperationalWithStatus({ commit }, status) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.REPORT_OPERATIONAL}?limit=100&page=1&status=${status}`); 
      if (response.data.status_code === 200) { 
        return response.data.data.data.map(ca => ({
          id: ca.ca_number,
          value: ca.ca_number,
          name: ca.ca_number // Adjusted to use module_name from response
        }));
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async createReportOther({ commit }, payload) { 
    console.log('create');
    
    commit('SET_LOADING', true); 
    try { 
      console.log(API_MASTER.CREATE_REPORT_OPERATIONAL);
      
      const response = await apiPostAuth(API_MASTER.CREATE_REPORT_OPERATIONAL, payload); 
      console.log(response);
      
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async updateReportOther({ commit }, payload) { 
    commit('SET_LOADING', true); 
    console.log(payload);
    
    try { 
      const response = await apiPutAuth(API_MASTER.UPDATE_REPORT_OPERATIONAL, payload); 
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async deleteReportOther({ commit }, payload) { 
    commit('SET_LOADING', true); 
    
    try { 
      const response = await apiDeleteAuth(`${API_MASTER.UPDATE_REPORT_OPERATIONAL}?ca_number=${payload.ca_number}`); 
      console.log(response);
      
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async getReportOtherById({ commit }, ca_number) { 
    commit('SET_LOADING', true); 
    
    try { 
      const response = await apiGetAuth(`${API_MASTER.DETAIL_REPORT_OPERATIONAL}?ca_number=${ca_number}`);
      if (response.data.status_code === 200) { 
        return response.data.data 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getBudgetOwner({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(API_MASTER.REPORT_OPERATIONAL_BUDGET); 
      if (response.data.status_code === 200) {
        
        return response.data.data.map(budgetOwner => ({
          id: budgetOwner.name,
          value: budgetOwner.name,
          name: budgetOwner.name // Adjusted to use module_name from response
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getBrand({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.REPORT_OPERATIONAL_BRAND}?limit=100&page=1&search=`); 
      if (response.data.status_code === 200) {
        return response.data.data.data.map(brand => ({
          id: brand.brand_code,
          value: brand.brand_lob,
          name: brand.brand_name // Adjusted to use module_name from response
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getExpenses({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(API_MASTER.REPORT_OPERATIONAL_EXPENSE+"?type=others"); 
      if (response.data.status_code === 200) {
        return response.data.data.map(expense => ({
          id: expense.id,
          name: expense.expense_name,
          value: expense.expense_code,
          expense_code: expense.expense_code,
          expense_name: expense.expense_name,
          expense_category_code: expense.expense_category_code,
          expense_category_name: expense.expense_category_name,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getEventById({ commit }, id ) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.EVENT}/${id}`); 
      if (response.data.status_code === 200) {
        // Return the mapped parent data
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getBranches({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.BRANCHES}`); 
      if (response.data.status_code === 200) {
        // Return the mapped branches data
        return response.data.data.map(branches => ({
          id: branches.id,
          value: branches.id,
          name: branches.name
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getOutlet({ commit }) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.BR_OUTLET}?limit=1000&page=1`); 
      if (response.data.status_code === 200) { 
        commit('SET_BR_OUTLETS', response.data.data.data); 
        commit('SET_TOTAL', response.data.data.total); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getTaxByCodes({ commit }, type) {
    commit('SET_LOADING', true);
    try {
      // Call the API for tax codes with the specified type
      const response = await apiGetAuth(`${API_MASTER.TAX}?type=${type}`);
      if (response.data.status_code === 200) {
        // Return the tax codes data
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getItems({ commit }) {
    commit('SET_LOADING', true);
    try {
      // Call the API for tax codes with the specified type
      const response = await apiGetAuth(`${API_MASTER.ITEM}?limit=100&page=1`);
      if (response.data.status_code === 200) {
        return response.data.data.data.map(item => ({
          id: item.item_code,
          value: item.item_code,
          name: item.item_name,
          percentage_badan : item.percentage_badan,
          percentage_perorangan : item.percentage_perorangan
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getUserById({ commit }, id) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.USER}/${id}`); 
      if (response.data.status_code === 200) { 
        return response.data.data;
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  setSelectedReportOther({ commit }, poEvent) {
    commit('SET_REPORT_OTHER', poEvent);
  }

}; 

const getters = { 
  getReportOperational(state) { 
    return state.report_operationals; 
  }, 
  getReportOther(state) { 
    return state.report_other; 
  }, 
  getTotalReportOthers(state) { 
    return state.total; 
  }, 
  isLoading(state) { 
    return state.loading; 
  }, 
  getError(state) { 
    return state.error; 
  } 
}; 

export default { 
  namespaced: true, 
  state, 
  mutations, 
  actions, 
  getters 
};
