import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth } from '@/utils/api';
import { API_MASTER, API_AUTH } from '@/utils/api-url';

const state = {
  brandings: [],
  branding: null,
  vendors: [], 
  vendor: null,
  activity: [],
  total: 0,
  loading: false,
  error: null,
  outlets: [],
  brands: [],
};

const mutations = {
  SET_PO_BRANDINGS(state, brandings) {
    state.brandings = brandings;
  },
  SET_PO_BRANDING(state, branding) {
    state.branding = branding;
  },
  SET_VENDORS(state, vendors) { 
    state.vendors = vendors; 
  }, 
  SET_VENDOR(state, vendor) { 
    state.vendor = vendor; 
  }, 
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_OUTLETS(state, outlets) {
    state.outlets = outlets;
  },
  SET_BRANDS(state, brands) {
    state.brands = brands;
  }
};

const actions = {
  async getPoBranding({ commit }, { limit, page, search }) {
    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_MASTER.PO_BRANDING}?limit=${limit}&page=${page}&search=${search}`);
      if (response.data.status_code === 200) {
        commit('SET_PO_BRANDINGS', response.data.data.data);
        commit('SET_TOTAL', response.data.data.total);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async createPoBranding({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await apiPostAuth(API_MASTER.CREATE_PO_BRANDING, payload);
      if (response.data.status_code === 200) {
        return response.data.message;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async updatePoBranding({ commit }, payload) {
    commit('SET_LOADING', true);

    try {
      const response = await apiPutAuth(API_MASTER.UPDATE_PO_BRANDING, payload);
      if (response.data.status_code === 200) {
        return response.data.message;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async deletePoBranding({ commit }, poNumber) {
    console.log('Deleting PO Branding:', poNumber);
    commit('SET_LOADING', true);
    try {
      const response = await apiDeleteAuth(`${API_MASTER.DELETE_PO_BRANDING}po_number=${poNumber}`);
      console.log(response)
      if (response.data.status_code === 200) {
        const updatedBrandings = state.brandings.filter(
          branding => branding.po_branding !== poNumber
        );
        commit('SET_PO_BRANDINGS', updatedBrandings);
        return response.data.message;
      }
    } catch (error) {
      console.error('Error deleting PO Branding:', error.message);
      commit('SET_ERROR', error.message);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getPoBrandingById({ commit }, po_number) {
    commit('SET_LOADING', true);

    try {
      const response = await apiGetAuth(`${API_MASTER.DETAIL_PO_BRANDING}po_number=${po_number}`);
      if (response.data.status_code === 200) {
        return response.data.data
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getBranding({ commit }) {

    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_MASTER.BRANDING}?limit=100&page=1&type=event`);
      if (response.data.status_code === 200) {
        return response.data.data.data.map(event => ({
          id: event.id,
          value: event.event_number,
          name: event.event_number // Adjusted to use module_name from response
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getBrandingById({ commit }, id) {

    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_MASTER.BRANDING}/${id}`);
      if (response.data.status_code === 200) {
        // Return the mapped parent data
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getBranches({ commit }) {

    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_AUTH.BRANCHES}`);
      if (response.data.status_code === 200) {
        // Return the mapped branches data
        return response.data.data.map(branches => ({
          id: branches.id,
          value: branches.id,
          name: branches.name
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getOutlet({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_MASTER.LOC_OUTLET}?limit=100&page=1`);
      if (response.data.status_code === 200) {
        commit('SET_OUTLETS', response.data.data.data);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getBrand({ commit }) {

    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_MASTER.ADVANCE_OTHERS_BRAND}?limit=100&page=1&search=`);
      if (response.data.status_code === 200) {
        commit('SET_BRANDS', response.data.data.data);

      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getTaxByCodes({ commit }, type) {
    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_MASTER.TAX}?type=${type}`);
      if (response.data.status_code === 200) {
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getActivity({ commit }, { limit = 30, page = 1 } = {}) {
    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_MASTER.ACTIVITY}?limit=${limit}&page=${page}`);
      if (response.data.status_code === 200) {
        return response.data.data.data;
      } else {
        throw new Error(response.data.message || 'Failed to fetch activity data.');
      }
    } catch (error) {
      console.error('Error fetching activity data:', error.message);
      commit('SET_ERROR', error.message);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },


  async getItems({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_MASTER.ITEM_BRAND}?limit=100&page=1`);
      if (response.data.status_code === 200) {
        return response.data.data.data
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getUserById({ commit }, id) {
    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_AUTH.USER}/${id}`);
      if (response.data.status_code === 200) {
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getBudgetInfo({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await apiPostAuth(API_MASTER.BUDGET_INFO, payload);
      console.log(response)
      if (response) {
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },


  async getVendorBranding({ commit }, { limit, page, search, status='', vendor_type='' }) {
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.VENDOR}?limit=${limit}&page=${page}&search=${search}&status=${status}&vendor_type=${vendor_type}`); 
      if (response.data.status_code === 200) { 
        
        commit('SET_VENDORS', response.data.data.data); 
        commit('SET_TOTAL', response.data.data.total); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  setSelectedPoBranding({ commit }, poBranding) {
    commit('SET_PO_BRANDING', poBranding);
  }

};

const getters = {
  getPoBrandings(state) {
    return state.brandings;
  },
  getPoBranding(state) {
    return state.branding;
  },
  getTotalPoBrandings(state) {
    return state.total;
  },
  isLoading(state) {
    return state.loading;
  },
  getError(state) {
    return state.error;
  },
  getOutlets(state) {
    return state.outlets;
  },
  getBrands(state) {
    return state.brands;
  },
  getVendors(state) { 
    return state.vendors; 
  }, 
  getVendor(state) { 
    return state.vendor; 
  }, 
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
