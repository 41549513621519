import { createStore } from 'vuex';
import auth from './modules/auth';
import account from './modules/account';
import user from './modules/user';
import menu from './modules/menu';
import role from './modules/role';
import mappingApproval from './modules/mapping-approval';
import outlet from './modules/outlet';
import targetVisit from './modules/target-visit';
import vendor from './modules/vendor';
import budget from './modules/budget';
import poEvent from './modules/po-event';
import poBranding from './modules/po-branding';
import invoiceEvent from './modules/invoice-event';
import invoiceBranding from './modules/invoice-branding';
import approvalPo from './modules/approval-po';
import approvalPoBranding from './modules/approval-po-branding';
import approvalInvoiceBranding from './modules/approval-invoice-branding';
import approvalInvoice from './modules/approval-invoice';
import approvalVendor from './modules/approval-vendor';
import programPromo from './modules/program-promo';
import advanceOthers from './modules/advance-others';
import advanceOperational from './modules/advance-operational';
import advanceCompensation from './modules/advance-compensation';
import settlementOthers from './modules/settlement-others';
import settlementOperational from './modules/settlement-operational';
import settlementCompensation from './modules/settlement-compensation';
import approvalCACompensation from './modules/approval-ca-compensation';
import approvalCAOthers from './modules/approval-ca-others';
import approvalCAOperational from './modules/approval-ca-operational';
import approvalSettlementCompensation from './modules/approval-settlement-compensation';
import approvalSettlementOthers from './modules/approval-settlement-others';
import approvalSettlementOperational from './modules/approval-settlement-operational';
import reportEvent from './modules/report/event';
import reportOthers from './modules/report/others';
import reportOperational from './modules/report/operational';
import reportBranding from './modules/report/branding';
import reportCompensation from './modules/report/compensation';
import heatmap from './modules/heatmap';

export default createStore({
  modules: {
    auth,
    account,
    user,
    menu,
    role,
    mappingApproval,
    outlet,
    targetVisit,
    vendor,
    budget,
    poEvent,
    poBranding,
    invoiceEvent,
    invoiceBranding,
    approvalPo,
    approvalPoBranding,
    approvalInvoiceBranding,
    approvalInvoice,
    approvalVendor,
    programPromo,
    advanceOthers,
    advanceOperational,
    advanceCompensation,
    settlementCompensation,
    settlementOthers,
    settlementOperational,
    approvalCACompensation,
    approvalCAOthers,
    approvalCAOperational,
    approvalSettlementCompensation,
    approvalSettlementOthers,
    approvalSettlementOperational,
    reportEvent,
    reportOthers,
    reportOperational,
    reportBranding,
    reportCompensation,
    heatmap
  }
});
