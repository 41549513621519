const BASE_URL_AUTH = `${process.env.VUE_APP_AUTH_SERVICE_URL}/v1/cms`;
const BASE_URL_MASTER = `${process.env.VUE_APP_MASTER_SERVICE_URL}/v1/cms`;
const BASE_URL_TRC = `${process.env.VUE_APP_TRC_SERVICE_URL}/v1/cms`;
const BASE_URL_LOC = `${process.env.VUE_APP_LOCATION_SERVICE_URL}/v1/cms`;
const BASE_URL_BUDGET = `${process.env.VUE_APP_BUDGET_URL}/api`;
// const BASE_URL_EMS = `${process.env.VUE_APP_EMS_SERVICE_URL}/api/budget-allocation/get/all/remaining`;

// Auth
export const API_AUTH = {
  LOGIN: `${BASE_URL_AUTH}/authorization/login`,
  LOGOUT: `${BASE_URL_AUTH}/authorization/logout`,
  FORGOT_PASSWORD: `${BASE_URL_AUTH}/authorization/forgot-password`,
  CHANGE_PASSWORD: `${BASE_URL_AUTH}/authorization/change-password`,
  PROFILE: `${BASE_URL_AUTH}/account/profile`,
  ROLE_MENU: `${BASE_URL_AUTH}/account/role-menu`,
  MENU: `${BASE_URL_AUTH}/master/menu`,
  CREATE_MENU: `${BASE_URL_AUTH}/master/menu`,
  UPDATE_MENU: `${BASE_URL_AUTH}/master/menu`,
  DETAIL_MENU: id => `${BASE_URL_AUTH}/master/menu/${id}`,
  PARENT: `${BASE_URL_AUTH}/master/menu/parent`,
  USER: `${BASE_URL_AUTH}/master/user`,
  CREATE_USER: `${BASE_URL_AUTH}/master/user`,
  UPDATE_USER: (id) => `${BASE_URL_AUTH}/master/user/${id}`,
  DETAIL_USER: id => `${BASE_URL_AUTH}/master/user/${id}`,
  DEPARTMENT: `${BASE_URL_AUTH}/master/user/departments`,
  REGION: `${BASE_URL_AUTH}/master/user/regions`,
  SUB_REGION: `${BASE_URL_AUTH}/master/user/sub-regions`,
  BRANCHES: `${BASE_URL_AUTH}/master/user/branches`,
  ROLES: `${BASE_URL_AUTH}/master/user/roles`,
  ROLE: `${BASE_URL_AUTH}/master/role`,
  CREATE_ROLE: `${BASE_URL_AUTH}/master/role`,
  UPDATE_ROLE: (id) => `${BASE_URL_AUTH}/master/role/${id}`,
  DETAIL_ROLE: id => `${BASE_URL_AUTH}/master/role/${id}`,
  MODULE: `${BASE_URL_AUTH}/master/module`,
  VENDOR: `${BASE_URL_AUTH}/master/vendors`,
  CREATE_VENDOR: `${BASE_URL_AUTH}/master/vendors`,
  UPDATE_VENDOR: `${BASE_URL_AUTH}/master/vendors`,
  DETAIL_VENDOR: id => `${BASE_URL_AUTH}/master/vendors/${id}`,
  UPLOAD_AGREEMENT: `${BASE_URL_AUTH}/master/vendors/upload-agreement`,
  GET_STATUS_VENDOR: `${BASE_URL_AUTH}/master/vendors/status`,
};

export const API_MASTER = {
  MENU: `${BASE_URL_MASTER}/master/menu`,
  CREATE_MENU: `${BASE_URL_MASTER}/master/menu`,
  UPDATE_MENU: `${BASE_URL_MASTER}/master/menu`,
  DETAIL_MENU: id => `${BASE_URL_MASTER}/master/menu/${id}`,
  PARENT: `${BASE_URL_MASTER}/master/menu/parent`,
  USER: `${BASE_URL_MASTER}/master/user`,
  CREATE_USER: `${BASE_URL_MASTER}/master/user`,
  UPDATE_USER: (id) => `${BASE_URL_MASTER}/master/user/${id}`,
  DETAIL_USER: id => `${BASE_URL_MASTER}/master/user/${id}`,
  DEPARTMENT: `${BASE_URL_MASTER}/master/user/departments`,
  REGION: `${BASE_URL_MASTER}/master/user/regions`,
  SUB_REGION: `${BASE_URL_MASTER}/master/user/sub-regions`,
  BRANCHES: `${BASE_URL_MASTER}/master/user/branches`,
  ROLES: `${BASE_URL_MASTER}/master/user/roles`,
  ROLE: `${BASE_URL_MASTER}/master/role`,
  CREATE_ROLE: `${BASE_URL_MASTER}/master/role`,
  UPDATE_ROLE: (id) => `${BASE_URL_MASTER}/master/role/${id}`,
  DETAIL_ROLE: id => `${BASE_URL_MASTER}/master/role/${id}`,
  MODULE: `${BASE_URL_MASTER}/master/module`,
  MAPPING_APPROVAL: `${BASE_URL_MASTER}/master/approval`,
  CREATE_MAPPING_APPROVAL: `${BASE_URL_MASTER}/master/approval`,
  UPDATE_MAPPING_APPROVAL: `${BASE_URL_MASTER}/master/approval`,
  DETAIL_MAPPING_APPROVAL: id => `${BASE_URL_MASTER}/master/approval/${id}`,
  TARGET_VISIT: `${BASE_URL_MASTER}/master/target-visit`,
  CREATE_TARGET_VISIT: `${BASE_URL_MASTER}/master/target-visit`,
  UPDATE_TARGET_VISIT: `${BASE_URL_MASTER}/master/target-visit`,
  DETAIL_TARGET_VISIT: id => `${BASE_URL_MASTER}/master/target-visit/${id}`,
  UPLOAD_TARGET_VISIT: `${BASE_URL_MASTER}/master/target-visit/upload`,
  BR_OUTLET: `${BASE_URL_MASTER}/master/br-outlet`,
  CREATE_BR_OUTLET: `${BASE_URL_MASTER}/master/br-outlet`,
  UPDATE_BR_OUTLET: `${BASE_URL_MASTER}/master/br-outlet`,
  DETAIL_BR_OUTLET: id => `${BASE_URL_MASTER}/master/br-outlet/${id}`,
  UPLOAD_BR_OUTLET: `${BASE_URL_MASTER}/master/br-outlet/upload`,
  BUDGET: `${BASE_URL_MASTER}/master/target-visit`,
  CREATE_BUDGET: `${BASE_URL_MASTER}/master/target-visit`,
  UPDATE_BUDGET: `${BASE_URL_MASTER}/master/target-visit`,
  DETAIL_BUDGET: id => `${BASE_URL_MASTER}/master/target-visit/${id}`,
  UPLOAD_BUDGET: `${BASE_URL_MASTER}/master/target-visit/upload`,
  PROVINCE: `${BASE_URL_MASTER}/master/regions/provinsi`,
  CITY: `${BASE_URL_MASTER}/master/regions/kabupaten`,
  DISTRICT: `${BASE_URL_MASTER}/master/regions/kecamatan`,
  VILLAGE: `${BASE_URL_MASTER}/master/regions/kelurahan`,
  BANK: `${BASE_URL_MASTER}/master/banks`,
  BRANCHES_BY_USER: `${BASE_URL_AUTH}/master/user/branches`,
  TAX: `${BASE_URL_MASTER}/master/tax-code`,

  EVENT: `${BASE_URL_TRC}/event`,
  DETAIL_EVENT: id => `${BASE_URL_TRC}/event/${id}`,

  ITEM: `${BASE_URL_MASTER}/master/detail-item`,
  ITEM_ATTACHMENT: `${BASE_URL_MASTER}/master/item-attachment`,
  ITEM_BRAND: `${BASE_URL_MASTER}/master/branding-item`,

  ACTIVITY: `${BASE_URL_MASTER}/master/activity`,

  PO_EVENT: `${BASE_URL_TRC}/po/event`,
  CREATE_PO_EVENT: `${BASE_URL_TRC}/po/event`,
  UPDATE_PO_EVENT: `${BASE_URL_TRC}/po/event`,
  DETAIL_PO_EVENT: `${BASE_URL_TRC}/po/event/by-po-number`,

  PO_BRANDING: `${BASE_URL_TRC}/po/branding`,
  PO_BRANDING_ACTIVE: `${BASE_URL_TRC}/po/branding/active`,
  CREATE_PO_BRANDING: `${BASE_URL_TRC}/po/branding`,
  UPDATE_PO_BRANDING: `${BASE_URL_TRC}/po/branding`,
  DETAIL_PO_BRANDING: `${BASE_URL_TRC}/po/branding/detail?`,
  DELETE_PO_BRANDING: `${BASE_URL_TRC}/po/branding?`,


  INVOICE_EVENT: `${BASE_URL_TRC}/invoice`,
  CREATE_INVOICE_EVENT: `${BASE_URL_TRC}/invoice`,
  UPDATE_INVOICE_EVENT: `${BASE_URL_TRC}/invoice`,
  DETAIL_INVOICE_EVENT: id => `${BASE_URL_TRC}/invoice/${id}`,

  INVOICE_BRANDING: `${BASE_URL_TRC}/invoice/branding`,
  CREATE_INVOICE_BRANDING: `${BASE_URL_TRC}/invoice/branding`,
  UPDATE_INVOICE_BRANDING: `${BASE_URL_TRC}/invoice/branding`,
  DETAIL_INVOICE_BRANDING: id => `${BASE_URL_TRC}/invoice/${id}`,

  APPROVAL_INVOICE: `${BASE_URL_TRC}/approval/invoice`,
  CREATE_APPROVAL_INVOICE: `${BASE_URL_TRC}/approval/invoice`,

  APPROVAL_PO: `${BASE_URL_TRC}/approval/po/event`,
  CREATE_APPROVAL_PO: `${BASE_URL_TRC}/approval/po/event`,


  APPROVAL_PO_BRANDING: `${BASE_URL_TRC}/approval/po/branding`,
  CREATE_APPROVAL_PO_BRANDING: `${BASE_URL_TRC}/approval/po/branding`,
  DETAIL_APPROVAL_PO_BRANDING: `${BASE_URL_TRC}/approval/po/branding/detail`,


  APPROVAL_INVOICE_BRANDING: `${BASE_URL_TRC}/approval/invoice/branding`,
  CREATE_APPROVAL_INVOICE_BRANDING: `${BASE_URL_TRC}/approval/invoice/branding`,

  APPROVAL_VENDOR: `${BASE_URL_AUTH}/master/approval/vendor`,
  CREATE_APPROVAL_VENDOR: `${BASE_URL_AUTH}/master/approval/vendor`,

  INVOICE_BY_NUMBER: `${BASE_URL_TRC}/invoice/by-invoice-number`,

  LOC_OUTLET: `${BASE_URL_LOC}/outlet`,

  PROGRAM_PROMO: `${BASE_URL_LOC}/promo-program`,
  CREATE_PROGRAM_PROMO: `${BASE_URL_LOC}/promo-program`,
  UPDATE_PROGRAM_PROMO: `${BASE_URL_LOC}/promo-program`,
  DETAIL_PROGRAM_PROMO: `${BASE_URL_LOC}/promo-program`,

  ADVANCE_OTHERS_BUDGET: `${BASE_URL_TRC}/advance/other/budget-owner`,
  ADVANCE_OTHERS_BRAND: `${BASE_URL_LOC}/master/brand`,
  ADVANCE_OTHERS_EXPENSE: `${BASE_URL_TRC}/advance/other/item-expense`,
  ADVANCE_OTHERS: `${BASE_URL_TRC}/advance/other`,
  CREATE_ADVANCE_OTHERS: `${BASE_URL_TRC}/advance/other`,
  UPDATE_ADVANCE_OTHERS: `${BASE_URL_TRC}/advance/other`,
  DETAIL_ADVANCE_OTHERS: `${BASE_URL_TRC}/advance/other/detail`,

  ADVANCE_COMPENSATION: `${BASE_URL_TRC}/advance/compensation`,
  CREATE_ADVANCE_COMPENSATION: `${BASE_URL_TRC}/advance/compensation`,
  DETAIL_ADVANCE_COMPENSATION: `${BASE_URL_TRC}/advance/compensation/detail`,
  DELETE_ADVANCE_COMPENSATION: `${BASE_URL_TRC}/advance/compensation`,

  APPROVAL_COMPENSATION: `${BASE_URL_TRC}/approval/ca/compensation`,
  CREATE_APPROVAL_COMPENSATION: `${BASE_URL_TRC}/approval/ca/compensation`,

  SETTLEMENT_COMPENSATION: `${BASE_URL_TRC}/advance/settlement/compensation`,
  DETAIL_SETTLEMENT_COMPENSATION: `${BASE_URL_TRC}/advance/settlement/compensation/detail`,
  CREATE_SETTLEMENT_COMPENSATION: `${BASE_URL_TRC}/advance/settlement/compensation`,

  APPROVAL_SETTLEMENT_COMPENSATION: `${BASE_URL_TRC}/approval/settle/compensation`,
  CREATE_APPROVAL_SETTLEMENT_COMPENSATION: `${BASE_URL_TRC}/approval/settle/compensation`,

  ADVANCE_OPERATIONAL_BUDGET: `${BASE_URL_TRC}/advance/operational/budget-owner`,
  ADVANCE_OPERATIONAL_EXPENSE: `${BASE_URL_TRC}/advance/operational/item-expense`,
  ADVANCE_OPERATIONAL: `${BASE_URL_TRC}/advance/operational`,
  CREATE_ADVANCE_OPERATIONAL: `${BASE_URL_TRC}/advance/operational`,
  UPDATE_ADVANCE_OPERATIONAL: `${BASE_URL_TRC}/advance/operational`,
  DETAIL_ADVANCE_OPERATIONAL: `${BASE_URL_TRC}/advance/operational/detail`,

  SETTLEMENT_OPERATIONAL_BUDGET: `${BASE_URL_TRC}/advance/other/budget-owner`,
  SETTLEMENT_OPERATIONAL_EXPENSE: `${BASE_URL_TRC}/advance/other/item-expense`,
  SETTLEMENT_OPERATIONAL: `${BASE_URL_TRC}/advance/settlement/operational`,
  CREATE_SETTLEMENT_OPERATIONAL: `${BASE_URL_TRC}/advance/settlement/operational`,
  UPDATE_SETTLEMENT_OPERATIONAL: `${BASE_URL_TRC}/advance/settlement/operational`,
  DETAIL_SETTLEMENT_OPERATIONAL: `${BASE_URL_TRC}/advance/settlement/operational/detail`,

  SETTLEMENT_OTHERS_BUDGET: `${BASE_URL_TRC}/advance/other/budget-owner`,
  SETTLEMENT_OTHERS_EXPENSE: `${BASE_URL_TRC}/advance/other/item-expense`,
  SETTLEMENT_OTHERS: `${BASE_URL_TRC}/advance/settlement`,
  CREATE_SETTLEMENT_OTHERS: `${BASE_URL_TRC}/advance/settlement`,
  UPDATE_SETTLEMENT_OTHERS: `${BASE_URL_TRC}/advance/settlement`,
  DETAIL_SETTLEMENT_OTHERS: `${BASE_URL_TRC}/advance/settlement/detail`,

  APPROVAL_OTHERS: `${BASE_URL_TRC}/approval/ca/other`,
  CREATE_APPROVAL_OTHERS: `${BASE_URL_TRC}/approval/ca/other`,
  UPDATE_APPROVAL_OTHERS: `${BASE_URL_TRC}/approval/ca/other`,
  DETAIL_APPROVAL_OTHERS: `${BASE_URL_TRC}/approval/ca/other/detail`,

  APPROVAL_OPERATIONAL: `${BASE_URL_TRC}/approval/ca/operational`,
  CREATE_APPROVAL_OPERATIONAL: `${BASE_URL_TRC}/approval/ca/operational`,
  UPDATE_APPROVAL_OPERATIONAL: `${BASE_URL_TRC}/approval/ca/operational`,
  DETAIL_APPROVAL_OPERATIONAL: `${BASE_URL_TRC}/approval/ca/operational/detail`,

  APPROVAL_SETTLEMENT_OTHERS: `${BASE_URL_TRC}/approval/settle/other`,
  CREATE_APPROVAL_SETTLEMENT_OTHERS: `${BASE_URL_TRC}/approval/settle/other`,
  UPDATE_APPROVAL_SETTLEMENT_OTHERS: `${BASE_URL_TRC}/approval/settle/other`,
  DETAIL_APPROVAL_SETTLEMENT_OTHERS: `${BASE_URL_TRC}/approval/settle/other/detail`,

  APPROVAL_SETTLEMENT_OPERATIONAL: `${BASE_URL_TRC}/approval/settle/operational`,
  CREATE_APPROVAL_SETTLEMENT_OPERATIONAL: `${BASE_URL_TRC}/approval/settle/operational`,
  UPDATE_APPROVAL_SETTLEMENT_OPERATIONAL: `${BASE_URL_TRC}/approval/settle/operational`,
  DETAIL_APPROVAL_SETTLEMENT_OPERATIONAL: `${BASE_URL_TRC}/approval/settle/operational/detail`,

  REPORT_EVENT: `${BASE_URL_TRC}/report/event`,
  REPORT_OTHERS: `${BASE_URL_TRC}/report/others`,
  REPORT_OPERATIONAL: `${BASE_URL_TRC}/report/operational`,
  REPORT_BRANDING: `${BASE_URL_TRC}/report/branding`,
  REPORT_COMPENSATION: `${BASE_URL_TRC}/report/compensation`,

  BUDGET_INFO: `${BASE_URL_TRC}/budget/remaining`,
  
  DASHBOARD: `${BASE_URL_MASTER}/master/dashboard`,

  HEATMAP: `${BASE_URL_LOC}/heatmap`
};

export const API_BUDGET= {
  BUDGET_REMINING: `${BASE_URL_BUDGET}/budget-allocation/get/all/remaining`
}